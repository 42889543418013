<template>
  <div @click="goToDetail(blog.entry.slug)" class="d-flex mt-2">
    <div class="me-2">
      <img :src="blog.image" class="rounded-s"  width="100" height="80"  />
    </div>
    <div>
      <h5
        class="mb-0 font-14 color-white"
      >
        {{ blog.entry.title }}
      </h5>
      <span class="mb-0 mt-n2 font-11 font-500 opacity-60 color-white">
        <timeago :datetime="blog.publication_date"
      /></span>
    </div>
  </div>
</template>

<script>
import { id } from "date-fns/locale"; // import custom locale
export default {
  name: "ListBlogHome",
  props: ["blog"],
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    goToDetail(slug) {
      this.$router.push({
        name: "ArticleDetail",
        params: { slug: slug },
      });
    },
    imageUrlAlt(event) {
      event.target.src = "/images/defaults/default_logo.png";
    },
  },
};
</script>
