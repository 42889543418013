<template>
  <div
    @click="goToDetail(job.slug)"
    class="card card-style mt-1"
    data-card-height="180"
  >
    <div class="content">
      <h4 class="mb-1">{{ job.title }}</h4>
      <!-- <h5
        class="font-13 font-600 opacity-70 line-height-s"
        style="color: #adb5bd"
      >
        {{ job.city.name }}
      </h5> -->
      <h5 class="font-13 font-600 opacity-70 line-height-s">
        <span v-if="job.get_level"
          >{{ job.get_level }} - {{ job.get_worktime_display }}
        </span>
      </h5>
      <h5 class="d-flex">
        <div class="font-13 opacity-70 mt-auto me-auto align-center">
          <span v-if="!(job.salary_start === job.salary_end)">
            Rp {{ $filters.formatPrice(job.salary_start) }} - Rp
            {{ $filters.formatPrice(job.salary_end) }}
          </span>
          <span v-if="job.salary_start === job.salary_end && !job.hide_salary">
            Sekitar Rp {{ $filters.formatPrice(job.salary_start) }}
          </span>
        </div>
        <!-- 
        <div>
          <router-link
            :to="{
              name: 'JobDetail',
              params: { id: job.slug },
              query: { invite_code: inviteCode },
            }"
            class="btn btn-sm rounded-sm font-10 font-600"
            style="background-color: rgb(255, 173, 58) !important"
            >Lihat</router-link
          >
        </div> -->
      </h5>
      <!-- Read More-->

      <div v-if="!job.company.hide_company" class="d-flex">
        <div class="me-auto">
          <div class="list-group list-custom-large mt-n3">
            <a href="#" class="border-0">
              <span class="font-13 text-truncate" style="max-width: 60%">{{
                job.company.business_name
              }}</span>
              <strong v-if="job.company.city">{{
                job.company.city.name
              }}</strong>
            </a>
          </div>
        </div>
        <div>
          <router-link
            :to="{
              name: 'JobDetail',
              params: { id: job.slug },
              query: { invite_code: inviteCode },
            }"
            class="btn btn-sm rounded-sm font-10 font-600"
            style="background-color: rgb(255, 173, 58) !important"
            >Lihat</router-link
          >
        </div>
      </div>

      <!-- End Read More-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ListJob",
  props: {
    job: Object,
    inviteCode: String,
  },
  inject: ["getApply"],
  data() {
    return {
      logoUrl: axios.defaults.baseURL + this.job.company.get_logo,
      timeLeft: this.getTime(this.job.get_time_left),
    };
  },
  mounted() {
    console.log("inviteCode : ", this.inviteCode);
  },
  methods: {
    goToDetail(id) {
      this.$router.push({
        name: "JobDetail",
        params: { id: id },
        query: { invite_code: this.inviteCode },
      });
    },
    imageUrlAlt(event) {
      event.target.src = "/images/defaults/default_logo.png";
    },
    getTime(time) {
      var re = /\d+(?=\s)/g;
      try {
        var timeLeft = time.match(re) ? time.match(re)[0] : "";
        let str = "";
        if (timeLeft) {
          str = "Berakhir " + timeLeft + " hari lagi";
        } else if (!timeLeft) {
          str = "Lowongan Berakhir Hari Ini";
        }
        return str;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
