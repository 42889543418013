<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-content">
      <div
        class="card notch-clear rounded-0 mb-n5"
        style="background-color: transparent"
      >
        <div class="card-body b pt-4 mt-2 mb-n2">
          <h1 class="font-30 float-start">
            <span class="font-900">PasJob</span>
            <span class="font-14"> #1 Hybrid Job & Freelance Portal</span>
          </h1>
          <div clas="clearfix"></div>
        </div>
        <!-- https://app.pasjob.com/article/detail/giveaway-pasjob-periode-juni -->
        <!-- Banner Top -->
        <div class="card-body mx-0 px-0 mt-n4 mb-5">
          <div
            class="splide single-slider slider-no-arrows slider-has-dots"
            id="single-slider-1"
          >
            <div class="splide__track">
              <div class="splide__list">
                <div class="splide__slide">
                  <router-link
                    :to="'/article/detail/giveaway-pasjob-periode-juni'"
                    tag="a"
                    data-card-height="190"
                    class="card card-style mb-0 mx-3 mt-4 text-center"
                    style="
                      background-image: url(images/banner/home-banner-event-50k.png);
                    "
                  ></router-link>
                </div>
                <div class="splide__slide">
                  <router-link
                    :to="{ name: 'Vacancy' }"
                    data-card-height="190"
                    class="card card-style mb-0 mx-3 mt-4 text-center"
                    style="
                      background-image: url(images/banner/home-banner-1.svg);
                    "
                  ></router-link>
                </div>
                <div class="splide__slide">
                  <router-link
                    :to="{ name: 'Vacancy' }"
                    data-card-height="190"
                    class="card card-style mb-0 mx-3 mt-4 text-center"
                    style="
                      background-image: url(images/banner/home-banner-2.svg);
                    "
                  ></router-link>
                </div>
                <div class="splide__slide">
                  <router-link
                    :to="{ name: 'SignUp', query: { user_type: 'employer' } }"
                    data-card-height="190"
                    class="card card-style mb-0 mx-3 mt-4 text-center"
                    style="
                      background-image: url(images/banner/home-banner-4.jpg);
                    "
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Banner Top -->

      <!-- Button Top -->
      <div class="mt-n3">
        <div class="content mb-2 mt-3">
          <div class="row mb-0">
            <div class="col-6 pe-1">
              <router-link
                :to="{ name: 'SignUp', query: { user_type: 'employer' } }"
                class="card card-style mx-0 mb-2 p-3"
                style="background-image: url(images/home-register-button.svg)"
                height="65"
                data-card-height="65"
              ></router-link>
            </div>
            <div class="col-6 ps-1">
              <router-link
                :to="{ name: 'SignUp', query: { user_type: 'job_seeker' } }"
                class="card card-style mx-0 mb-2 p-3"
                style="background-image: url(images/home-register-button-1.svg)"
                height="65"
                data-card-height="65"
              ></router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div
          class="search-box bg-white border-highlight rounded-xl mb-3"
        >

<!-- search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0 -->
          <i class="fa fa-search custom-input-search"></i>
          <input
            type="text"
            class="border-0"
            placeholder="Cari Kerja"
            v-model="dataSearch"
            @keyup.enter="searchFilter"
          />
        </div>
      </div>
      <h5 class="font-20 text-center font-600 p-3 m-3" style="color: #66c152">
        Terpercaya dan telah digunakan oleh berbagai perusahaan terkemuka di
        Indonesia
      </h5>
      <!-- End Button Top -->
      <div
        class="splide story-slider slider-no-dots mt-4 mb-4 is-active bg-light rounded-3"
        id="topic-slider-1"
      >
        <div class="splide__track">
          <div class="splide__list">
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/prulifeuk.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/aice.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/ciputradevelopment.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/frisianflag.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/honda.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/myrepublic.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide mt-3">
              <h1 class="font-16 d-block">
                <img
                  src="images/logo_dashboard/prulifeuk.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/shirokuma.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/suzuki.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
            <div class="splide__slide">
              <h1 class="font-16 d-block mt-3">
                <img
                  src="images/logo_dashboard/wuling.jpeg"
                  alt=""
                  style="max-width: 50px; max-height: 50"
                />
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-style bg-highlight">
        <h5 class="font-26 text-center font-800 pt-3 my-3 color-white">
          Lowongan kerja pilihan
        </h5>

        <div class="card-body mx-0 px-0 mt-1">
          <div
            class="splide single-slider slider-no-arrows slider-no-dots"
            id="single-slider-2"
          >
            <div class="splide__track">
              <div class="splide__list">
                <div class="splide__slide">
                  <ListRecomendedJob
                    v-for="job in listJob"
                    :key="job.slug"
                    :job="job"
                    :inviteCode="inviteCode"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link
        :to="{ name: 'RequestTalent' }"
        data-card-height="200"
        class="card card-style"
        style="
          height: 200;
          padding: 0;
          background-image: url(images/banner/home-request-talent.png);
        "
      >
        <div class="card-body m-2">
          <h6 class="text-white mb-4">Cari Talent Sekarang!</h6>
          <div class="text-white">
            Tim pasjob dapat membantu kamu untuk segera
            <br />mencari kandidat yang cocok dengan kebutuhan <br />bisnismu,
            silahkan isi form berikut!
          </div>
          <button
            class="btn btn-s mt-2 font-600 rounded-s text-white"
            style="background-color: #ffad3a !important"
          >
            Request Talent Form
          </button>
        </div>
      </router-link>

      <!-- section Quick Step -->
      <div
        data-card-height="180"
        class="card card-style bg-16"
        style="height: 180"
      >
        <div class="card-top text-center pt-2">
          <h5 class="color-gray-dark font-000 font-14">
            Mulai temukan pekerjaan impianmu
          </h5>
        </div>
        <div class="card-center mt-2">
          <div class="row ms-2 me-2 mb-0">
            <div class="col-4 text-center line-height-s">
              <img
                src="images/icon/home-signUp.svg"
                class="img-fluid mb-2"
                width="46"
                height="46"
              />
              <p class="mb-1 font-12 color-highlight font-500">Buat Akun</p>
              <p class="font-10 line-height-xs mb-0">
                Daftarkan dirimu sekarang juga
              </p>
            </div>
            <div class="col-4 text-center line-height-s">
              <img
                src="images/icon/home-find.svg"
                class="img-fluid rounded-sm mb-2"
                width="46"
                height="46"
              />
              <p class="mb-1 font-12 color-highlight font-500">Temukan</p>
              <p class="font-10 line-height-xs mb-0">
                Cari pekerjaan yang sesuai dengan kamu
              </p>
            </div>
            <div class="col-4 text-center line-height-s">
              <img
                src="images/icon/home-apply.svg"
                class="img-fluid mb-2"
                style="height: 43"
              />
              <p class="mb-1 font-12 color-highlight font-500">Lamar</p>
              <p class="font-10 line-height-xs mb-0">
                Lamar segera sebelum berakhir
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Section Quick Step -->

      <!-- section banner job seeker -->
      <div
        data-card-height="450"
        class="card card-style bg-highlight"
        style="height: 450px"
      >
        <div class="card-top text-center pt-4">
          <h5
            class="color-white"
            style="
              font-family: Poppins !important;
              font-style: normal;
              font-weight: bold !important;
              font-size: 20px !important;
              line-height: 30px !important;
            "
          >
            Pekerjaan Yang <span style="color: #8c8c8c">Pas</span> <br />
            Untuk Kamu
          </h5>
        </div>

        <div class="card-center text-center">
          <img src="images/icon/home-job-seeker.svg" alt="" />
          <p class="boxed-text-xl mt-3 font-500 font-12 color-white">
            Daftar pekerjaan akan disesuaikan dengan profil kamu dan bisa
            digunakan gratis oleh semua pengguna
          </p>
        </div>
        <div class="card-bottom text-center mb-1">
          <div class="row">
            <div class="col-6 pe-0">
              <router-link
                :to="{ name: 'Vacancy' }"
                class="btn btn-l rounded-xl mb-3 font-12 font-600"
                style="background-color: #ffad3a !important"
                >Cek Lowongan</router-link
              >
            </div>
            <div class="col-6 ps-0">
              <router-link
                :to="{ name: 'Project' }"
                class="btn btn-l rounded-l mb-3 font-12 font-600"
                style="background-color: #ffad3a !important"
                >Cek Freelance</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- section banner job seeker -->

      <!-- section banner company -->
      <div
        data-card-height="350"
        class="card card-style bg-highlight"
        style="height: 350px"
      >
        <div class="card-top text-center pt-4">
          <h5
            class="color-white"
            style="
              font-family: Poppins !important;
              font-style: normal;
              font-weight: bold !important;
              font-size: 20px !important;
              line-height: 30px !important;
            "
          >
            Gratis <span style="color: #8c8c8c">Pas</span>ang <br />
            Lowongan
          </h5>
        </div>

        <div class="card-bottom text-center">
          <img src="images/icon/home-company.svg" alt="" />
          <p class="boxed-text-xl mt-3 font-500 font-12 color-white">
            Sebagai Bisnis, anda bisa daftarkan lowongan pekerjaan secara
            gratis!
          </p>
        </div>
      </div>
      <!-- section banner company -->

      <!-- section Articel -->
      <div class="card card-style bg-highlight">
        <div class="pt-4">
          <h5
            class="color-white text-center"
            style="
              font-family: Poppins !important;
              font-style: normal;
              font-weight: bold !important;
              font-size: 20px !important;
              line-height: 30px !important;
            "
          >
            Artikel
          </h5>
          <div class="content">
            <listBlogHome
              v-for="blog in listBlog"
              :key="blog.entry.id"
              :blog="blog"
            />
          </div>
        </div>
        <div v-if="listBlog.length" @click="goToArticle" class="text-end">
          <div class="content">
            <p class="color-white font-800 font-16">See All</p>
          </div>
        </div>
      </div>
      <!-- section Articel -->

      <div class="card card-style">
        <h4 class="font-26 text-center color-theme font-800 pt-3 mt-3">
          PasJob
        </h4>
        <p class="boxed-text-l mb-4">
          Temukan Pekerjaan Impianmu <br />Tentukan Masa depanmu.
        </p>
        <div class="text-center mb-4">
          <a
            href="https://www.facebook.com/pasjobofficial12/"
            class="icon icon-s rounded-sm shadow-l text-white bg-facebook me-3"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            href="https://www.instagram.com/pasjobofficial/"
            class="icon icon-s rounded-sm shadow-l bg-instagram me-3"
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            href="https://www.tiktok.com/@pasjoboffical?_t=8Y7KQFBM7DW&_r=1"
            class="icon icon-s rounded-sm shadow-l text-white bg-dark me-3"
            ><i class="fab fa-tiktok"></i
          ></a>
          <a
            href="https://t.me/lokertalenta"
            data-menu="menu-share"
            class="icon icon-s rounded-sm mr-2 shadow-l text-light bg-primary me-3"
            ><i class="fab fa-telegram"></i
          ></a>
          <!-- <a
            href="#"
            class="
              back-to-top
              icon icon-xs
              rounded-sm
              shadow-l
              bg-highlight
              color-white
            "
            ><i class="fa fa-arrow-up"></i
          ></a> -->
        </div>
        <div class="divider mb-3"></div>
        <div class="row text-center mb-3 pl-3 pr-3">
          <router-link
            :to="{ name: 'PrivacyPolicy' }"
            class="font-11 col-6"
            href="#"
            >Kebijakan Privasi</router-link
          >
          <router-link :to="{ name: 'Term' }" class="font-11 col-6" href="#"
            >Syarat dan Ketentuan</router-link
          >
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
  </div>
</template>

<script>
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import ListRecomendedJob from "@/components/list_job/ListRecomendedJob";
import ListBlogHome from "@/components/blog/ListBlogHome";

export default {
  name: "Home",
  data() {
    return {
      errors: [],
      inviteCode: "",
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      listJob: [],
      listBlog: [],
      dataSearch: "",
    };
  },
  components: {
    Header,
    Footer,
    ListRecomendedJob,
    ListBlogHome,
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 50);
    this.getJobPilihan();
    this.getBlog();
  },
  methods: {
    menuClose,
    menuOpen,
    goToArticle() {
      this.$router.push({
        name: "ArticleList",
      });
    },
    searchFilter() {
      console.log("searching");
      this.$router.push({ name: "Vacancy", query: { f: this.dataSearch } });
    },
    getBlog() {
      axios
        // .get("/api/v1/job-seeker/job-list/vacancy")
        .get("/api/v1/blog/")
        .then(({ data }) => {
          data.results.forEach((item, index) => {
            if (index < 3) {
              this.listBlog.push(item);
            }
          });
          // this.listBlog = data.results;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    getJobPilihan() {
      axios
        // .get("https://pasjob.jak.asia/nodejs/job/recommended-job")
        .get("/job/recommended-job", {
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          data.data.forEach((item) => {
            this.listJob.push(item);
          });
          // this.historyPoint = [
          //   ...this.historyPoint,
          //   ...data.data.data
          // ];
          // this.point = data.data.point;
          // this.summaryPointPayload.page = data.data.next_page
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>

<style scoped>
.bg-gradient-banner-1 {
  background: linear-gradient(to left, #66c152, #a0d468 100%) !important;
}


input::placeholder {
  color: green;
  font-size: 1.2em;
}

.search-box .fa.fa-search.custom-input-search {
  color: green !important ;
  position: absolute;
  right: 0px !important;
  left:unset !important;
  line-height: 52px;
  padding: 0px 40px 0px 30px;
}

.force-opaque::placeholder {
  opacity: 1;
}
</style>
}
